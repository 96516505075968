try {
	window.Popper = require('popper.js').default;
	window.$ = window.jQuery = require('jquery');

	require('bootstrap');
} catch (e) {}

// window.Fortawesome = require('@fortawesome/fontawesome-pro/js/all');

// jQuery should work here xD

console.log('mint init()');

const axios = require('axios');

$("#romaBusinessCardForm").submit(function(event) {
	event.preventDefault();

	let url = "/api/sendcontactform.php?" + $(this).serialize();
	console.log(url);

	axios.get(url)
		.then(function (response) {
			// handle success
			console.log("success", response);
			$('#romaBusinessCardForm').hide();
			if (response.data.success) {
				$('#successView').show();
				$('#successMessage').html(response.data.message);
			} else {
				$('#errorView').show();
				$('#errorMessage').html(response.data.message);
			}
		})
		.catch(function (error) {
			// handle error
			console.log("error", error);
		})
	;


});

(function() {

	'use strict';

	// define variables
	var items = document.querySelectorAll(".benefits li");

	// check if an element is in viewport
	// http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
	function isElementInViewport(el) {
		var rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	function callbackFunc() {
		for (var i = 0; i < items.length; i++) {
			if (isElementInViewport(items[i])) {
				items[i].classList.add("in-view");
			}
		}
	}

	// listen for events
	window.addEventListener("load", callbackFunc);
	window.addEventListener("resize", callbackFunc);
	window.addEventListener("scroll", callbackFunc);

})();
